import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import NavButtons from 'components/Advanced_User/INSTAR_MQTT_Broker/NavButtons';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "INSTAR MQTT Server with homee",
  "path": "/Advanced_User/INSTAR_MQTT_Broker/homee/",
  "dateChanged": "2019-03-26",
  "author": "Mike Polinowski",
  "excerpt": "MQTT (Message Queuing Telemetry Transport) is a machine-to-machine (M2M)/ Internet of Things (IoT) connectivity protocol. It was designed as an extremely lightweight publish/subscribe messaging transport. It is useful for connections with remote locations where a small code footprint is required and/or network bandwidth is at a premium. All Full HD cameras now offer an internal MQTT broker: IN-8003 Full HD, IN-8015 Full HD, IN-9008 Full HD, IN-9010 Full HD, IN-9020 Full HD.",
  "image": "../AU_SearchThumb_MQTT.png",
  "social": "/images/Search/AU_SearchThumb_MQTT.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_Advanced_MQTT_white.webp",
  "chapter": "Advanced User",
  "category": "smarthome",
  "type": "homee"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <SEOHelmet title='INSTAR MQTT Server with homee' dateChanged='2019-03-26' author='Mike Polinowski' tag='INSTAR IP Camera' description='MQTT (Message Queuing Telemetry Transport) is a machine-to-machine (M2M)/ Internet of Things (IoT) connectivity protocol. It was designed as an extremely lightweight publish/subscribe messaging transport. It is useful for connections with remote locations where a small code footprint is required and/or network bandwidth is at a premium. All Full HD cameras now offer an internal MQTT broker: IN-8003 Full HD, IN-8015 Full HD, IN-9008 Full HD, IN-9010 Full HD, IN-9020 Full HD.' image='/images/Search/AU_SearchThumb_MQTT.png' twitter='/images/Search/AU_SearchThumb_MQTT.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Erweitert/INSTAR_MQTT_Broker/homee/' locationFR='/fr/Advanced_User/INSTAR_MQTT_Broker/homee/' crumbLabel="MQTT" mdxType="BreadCrumbs" />
    <NavButtons mdxType="NavButtons" />
    <h1 {...{
      "id": "instar-mqtt-server-with-homee",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#instar-mqtt-server-with-homee",
        "aria-label": "instar mqtt server with homee permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`INSTAR MQTT Server with homee`}</h1>
    {/* TOC */}
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#node-red-configuration"
        }}>{`Node-RED Configuration`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#connecting-the-homee-gateway"
        }}>{`Connecting the homee Gateway`}</a>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "#using-homee-environment-variables"
            }}>{`Using homee Environment Variables`}</a>
            <ul parentName="li">
              <li parentName="ul"><a parentName="li" {...{
                  "href": "#flow-download"
                }}>{`Flow Download`}</a></li>
            </ul>
          </li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#connecting-a-sensor-to-your-camera"
            }}>{`Connecting a Sensor to your Camera`}</a>
            <ul parentName="li">
              <li parentName="ul"><a parentName="li" {...{
                  "href": "#flow-download-1"
                }}>{`Flow Download`}</a></li>
            </ul>
          </li>
        </ul>
      </li>
    </ul>
    {/* /TOC */}
    <h2 {...{
      "id": "node-red-configuration",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#node-red-configuration",
        "aria-label": "node red configuration permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Node-RED Configuration`}</h2>
    <p>{`If you aren't using Node-RED already please follow our tutorials on how to set up the program under `}<a parentName="p" {...{
        "href": "/en/Advanced_User/Node-RED_and_MQTT/"
      }}>{`Windows`}</a>{`, `}<a parentName="p" {...{
        "href": "/en/Software/Linux/Node-RED/"
      }}>{`LINUX Debian`}</a>{` or on a `}<a parentName="p" {...{
        "href": "/en/Advanced_User/Homematic_CCU3_and_RedMatic/"
      }}>{`Homematic CCU3`}</a>{`. If you are using a Raspberry Pi to run Node-RED, you can use the `}<a parentName="p" {...{
        "href": "https://nodered.org/docs/getting-started/raspberrypi"
      }}>{`official installation script`}</a>{` to have the program set up automatically for you.`}</p>
    <p>{`You can follow our tutorial on `}<a parentName="p" {...{
        "href": "/en/Advanced_User/INSTAR_MQTT_Broker/Node-RED/"
      }}>{`how to connect Node-RED to your INSTAR Full HD camera MQTT interface`}</a>{`.`}</p>
    <h3 {...{
      "id": "using-homee-environment-variables",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#using-homee-environment-variables",
        "aria-label": "using homee environment variables permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Using homee Environment Variables`}</h3>
    <p>{`First we are going to use the homee `}<strong parentName="p">{`Webhook`}</strong>{` service to trigger a Node-RED Flow for us. As a trigger I want to use the homee system variables, like `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`away`}</code>{`, `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`home`}</code>{`, `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`vacation`}</code>{`. Let's start by creating a webhook in Node-RED that we can contact from the homee gateway:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "797px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/405e27d35f8ea5628d4186c17e028bb8/43fbc/homee_node-red-mqtt_06.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "26.08695652173913%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAFCAIAAADKYVtkAAAACXBIWXMAAA7DAAAOwwHHb6hkAAAA5ElEQVQY03XLTUvDMByA8X6aNn+9DwpS2M3vqEd3UgZTb4J6UBFKcUzGpoJK0a3Epmvem5o0YsHb/B0feIK9wWB/OEySJI5jAEAIAUAURagXoSgMQwS/cae3+wcAAkYpIUQp1bYtY4wQwjmrKiK4MLpRUtKaSiGFEKznnLPWaq0554FSCuNSay2loJR577uu894XVTG5HU9uxuf3p4v3ud8myIvyKltepovl68cXXmP8kOfX61X2uXrKHk+y2fHz251QYvs8fZkenh2MLo7SeVpvNsZQpcqmqZ01tmX2m3fO+H/8AK4+6XWspBzEAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/405e27d35f8ea5628d4186c17e028bb8/e4706/homee_node-red-mqtt_06.avif 230w", "/en/static/405e27d35f8ea5628d4186c17e028bb8/d1af7/homee_node-red-mqtt_06.avif 460w", "/en/static/405e27d35f8ea5628d4186c17e028bb8/48116/homee_node-red-mqtt_06.avif 797w"],
              "sizes": "(max-width: 797px) 100vw, 797px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/405e27d35f8ea5628d4186c17e028bb8/a0b58/homee_node-red-mqtt_06.webp 230w", "/en/static/405e27d35f8ea5628d4186c17e028bb8/bc10c/homee_node-red-mqtt_06.webp 460w", "/en/static/405e27d35f8ea5628d4186c17e028bb8/9eee1/homee_node-red-mqtt_06.webp 797w"],
              "sizes": "(max-width: 797px) 100vw, 797px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/405e27d35f8ea5628d4186c17e028bb8/81c8e/homee_node-red-mqtt_06.png 230w", "/en/static/405e27d35f8ea5628d4186c17e028bb8/08a84/homee_node-red-mqtt_06.png 460w", "/en/static/405e27d35f8ea5628d4186c17e028bb8/43fbc/homee_node-red-mqtt_06.png 797w"],
              "sizes": "(max-width: 797px) 100vw, 797px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/405e27d35f8ea5628d4186c17e028bb8/43fbc/homee_node-red-mqtt_06.png",
              "alt": "homee Node-RED MQTT Verbindung zu Ihrer INSTAR IP-Kamera",
              "title": "homee Node-RED MQTT Verbindung zu Ihrer INSTAR IP-Kamera",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`You can import this flow to your Node-RED panel with the following JSON code:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "json"
    }}><pre parentName="div" {...{
        "className": "language-json"
      }}><code parentName="pre" {...{
          "className": "language-json"
        }}><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"id"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"181fefd6.961e6"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"type"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"http in"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"z"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"4ea30e4c.29d4"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"name"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"homee env"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"url"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"/homee/environment/"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"method"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"post"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"upload"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token boolean"
          }}>{`false`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"swaggerDoc"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`""`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"x"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`80`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"y"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`340`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"wires"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"405534e7.66348c"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"bec44bcd.5eb548"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"id"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"405534e7.66348c"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"type"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"http response"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"z"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"4ea30e4c.29d4"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"name"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"Webhook"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"statusCode"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`""`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"headers"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"content-type"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"application/json"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"x"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`228`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"y"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`340`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"wires"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"id"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"bec44bcd.5eb548"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"type"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"debug"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"z"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"4ea30e4c.29d4"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"name"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`""`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"active"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token boolean"
          }}>{`true`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"tosidebar"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token boolean"
          }}>{`true`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"console"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token boolean"
          }}>{`false`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"tostatus"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token boolean"
          }}>{`false`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"complete"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"false"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"x"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`240`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"y"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`299`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"wires"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span></code></pre></div>
    <p>{`The webhook we created will wait to receive a `}<strong parentName="p">{`POST`}</strong>{` request on the URL `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`/homee/environment/`}</code>{`:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "816px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/911e42809e8719e5733329927fa44011/b4098/homee_node-red-mqtt_07.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "44.78260869565218%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAJCAIAAAC9o5sfAAAACXBIWXMAAA7DAAAOwwHHb6hkAAABeUlEQVQoz23PXU+CYBQHcL6jLd/m3Lrruu/Qbd/ArTanbTVjNs0XEMRHEEHU9CJ1JrK5iRcp0XjgAZ4Sstnqd3Hu/ud/DpFOJs/S6VQqlUgkIpHIyR+n/4nG49FYjGhxHNfkeJ4XBKHZbLIsyzCMoiiz2Ww6nS4Wi81msz6iB1bz+WqhEqIoAgBkWRZFUZKk8Wg8HAw1TbNt23EcCKEVgAE7nAiJ5xejyyuCZgFZpsgSRTMc32KE1iNL3U1eBobxvl6vIYSe5yGE3APP83a7na5pb7pOFKvFzG3m+v6mStc6PJA6DA9qr/MJQi6Elhfwfwt3+BgTFKAKdIFsPNAcLQid4fBZlhVV1UzTDGsNw0CBsDbc5bouxpgAladGLtvIZVtUvdPtDvp9SZKWyyUOuK5rBLbbrW3bGOOf/n24QrN5spwnSwwHlF5PCcKqqpqmiRDyff/jACHkHdmHOQCqFFWp1dttXpbl/qHZsizHccKzLcv6fvXIV/gTWxy8PYCwXMQAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/911e42809e8719e5733329927fa44011/e4706/homee_node-red-mqtt_07.avif 230w", "/en/static/911e42809e8719e5733329927fa44011/d1af7/homee_node-red-mqtt_07.avif 460w", "/en/static/911e42809e8719e5733329927fa44011/7cc10/homee_node-red-mqtt_07.avif 816w"],
              "sizes": "(max-width: 816px) 100vw, 816px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/911e42809e8719e5733329927fa44011/a0b58/homee_node-red-mqtt_07.webp 230w", "/en/static/911e42809e8719e5733329927fa44011/bc10c/homee_node-red-mqtt_07.webp 460w", "/en/static/911e42809e8719e5733329927fa44011/79303/homee_node-red-mqtt_07.webp 816w"],
              "sizes": "(max-width: 816px) 100vw, 816px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/911e42809e8719e5733329927fa44011/81c8e/homee_node-red-mqtt_07.png 230w", "/en/static/911e42809e8719e5733329927fa44011/08a84/homee_node-red-mqtt_07.png 460w", "/en/static/911e42809e8719e5733329927fa44011/b4098/homee_node-red-mqtt_07.png 816w"],
              "sizes": "(max-width: 816px) 100vw, 816px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/911e42809e8719e5733329927fa44011/b4098/homee_node-red-mqtt_07.png",
              "alt": "homee Node-RED MQTT Verbindung zu Ihrer INSTAR IP-Kamera",
              "title": "homee Node-RED MQTT Verbindung zu Ihrer INSTAR IP-Kamera",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Great! Now we can switch to the home dashboard and create a automation that is able to utilize this webhook. Click to create a new `}<strong parentName="p">{`Homeegram`}</strong>{`:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "894px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/02b2d8076f9690efcc915155fbf84399/38af3/homee_node-red-mqtt_08.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "20%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAECAYAAACOXx+WAAAACXBIWXMAAA7CAAAOwgEVKEqAAAABEklEQVQY002N0UrCYABG9xi+gPzSLsrY/oEK04ulDMXCwKTBMMJkshGBdiHUU3QrBMHUoEyDih6gJwiqIfQQ3dU4oUJ0cfgO5+ZTXNelXq/TaDTodDp4noez7+A0XXoHHl8nIXF/Qnw2Ie7f8hOExMFwuf+Jg5DvIESpVCrUarUlCzdNk0KhQC6XoWhvczN95f75k+HjnPHDnPEsYnT3xmj6sfJZxPUs4moa8fL0jpLNZimXy9i2jZQ6Qgg202nWVBXLzOO2A6pHAdWWT7W1cJ/d7jk7fnfV2gE73jGWc8hgcImi6zpSyj8Mw8CQkrSm4Teb7Gka+WSSrVQKKyWwhKC0vkFxcSjEspVUlUwiwUXvlF8J9bUEA4rrmAAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/02b2d8076f9690efcc915155fbf84399/e4706/homee_node-red-mqtt_08.avif 230w", "/en/static/02b2d8076f9690efcc915155fbf84399/d1af7/homee_node-red-mqtt_08.avif 460w", "/en/static/02b2d8076f9690efcc915155fbf84399/542e7/homee_node-red-mqtt_08.avif 894w"],
              "sizes": "(max-width: 894px) 100vw, 894px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/02b2d8076f9690efcc915155fbf84399/a0b58/homee_node-red-mqtt_08.webp 230w", "/en/static/02b2d8076f9690efcc915155fbf84399/bc10c/homee_node-red-mqtt_08.webp 460w", "/en/static/02b2d8076f9690efcc915155fbf84399/26e8f/homee_node-red-mqtt_08.webp 894w"],
              "sizes": "(max-width: 894px) 100vw, 894px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/02b2d8076f9690efcc915155fbf84399/81c8e/homee_node-red-mqtt_08.png 230w", "/en/static/02b2d8076f9690efcc915155fbf84399/08a84/homee_node-red-mqtt_08.png 460w", "/en/static/02b2d8076f9690efcc915155fbf84399/38af3/homee_node-red-mqtt_08.png 894w"],
              "sizes": "(max-width: 894px) 100vw, 894px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/02b2d8076f9690efcc915155fbf84399/38af3/homee_node-red-mqtt_08.png",
              "alt": "homee Node-RED MQTT Verbindung zu Ihrer INSTAR IP-Kamera",
              "title": "homee Node-RED MQTT Verbindung zu Ihrer INSTAR IP-Kamera",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Click on `}<strong parentName="p">{`When`}</strong>{` to set the condition that should be met for the action to be triggered and select `}<strong parentName="p">{`Mode`}</strong>{`:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "895px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/7c2b2266515676fc989824356e596e88/fcbaf/homee_node-red-mqtt_09.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "30.869565217391305%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAGCAIAAABM9SnKAAAACXBIWXMAAA7DAAAOwwHHb6hkAAABI0lEQVQY013CT0vCUAAA8H2gnNPw7f2z/cu9tTZxr23O5DE9ROnCNoxOGxgUHfLWpbtCQYdufaMuCdFHCDqZP36SECIMwziOsyybTCZJPxmIYZGef87X37fvm+uXzWy1uVrv/JqtfuavEmMsjuMoiizLUhQFI9QCoKU0Us0563RF2z7Fh0Pyr2jbvIGfbhYSQsg0TcMwAACyLO83m3v1euK6F+7xiAcDxzkxzdCytke27avqc1lKAKoIY0opIYT+ARDm0+nY74ZWJ9B0n9AubW/vHWidRnNZVtLH+m1599BPkvFonKapEIIxluf5ketqukYpRRDtIITUZLmsKulxcV9cznpBj3MeBAHn3PO8oigYY8y2DV2Hqoog3E4wlmu1qix/AboOd/y+iYmBAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/7c2b2266515676fc989824356e596e88/e4706/homee_node-red-mqtt_09.avif 230w", "/en/static/7c2b2266515676fc989824356e596e88/d1af7/homee_node-red-mqtt_09.avif 460w", "/en/static/7c2b2266515676fc989824356e596e88/d176d/homee_node-red-mqtt_09.avif 895w"],
              "sizes": "(max-width: 895px) 100vw, 895px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/7c2b2266515676fc989824356e596e88/a0b58/homee_node-red-mqtt_09.webp 230w", "/en/static/7c2b2266515676fc989824356e596e88/bc10c/homee_node-red-mqtt_09.webp 460w", "/en/static/7c2b2266515676fc989824356e596e88/dcb2d/homee_node-red-mqtt_09.webp 895w"],
              "sizes": "(max-width: 895px) 100vw, 895px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/7c2b2266515676fc989824356e596e88/81c8e/homee_node-red-mqtt_09.png 230w", "/en/static/7c2b2266515676fc989824356e596e88/08a84/homee_node-red-mqtt_09.png 460w", "/en/static/7c2b2266515676fc989824356e596e88/fcbaf/homee_node-red-mqtt_09.png 895w"],
              "sizes": "(max-width: 895px) 100vw, 895px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/7c2b2266515676fc989824356e596e88/fcbaf/homee_node-red-mqtt_09.png",
              "alt": "homee Node-RED MQTT Verbindung zu Ihrer INSTAR IP-Kamera",
              "title": "homee Node-RED MQTT Verbindung zu Ihrer INSTAR IP-Kamera",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Select `}<strong parentName="p">{`Mode is set to Home`}</strong>{` and click on done to save the condition:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "897px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/f86b77cf6c319ce0406b4fe9c8425c4b/3a737/homee_node-red-mqtt_10.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "54.78260869565218%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAA7CAAAOwgEVKEqAAAACdElEQVQoz1WSS08TYRSG53cooAV1oNNSCghtZ2iFXull2k7vF0skFWqYQkzApGLswhixBmLrQiExwagxcc0Kt/C7HjOf0ODizTn5Fs/3nvcc6fNhn87uC/KFAvV6nVqtRrVaJRwOY5ommt/PfVnG7nAwpShMKfYb9Z8Up5ORsTG2TBPp9/Epb/ZfY+RzlEolisUi5XKZeCJB2zRJhUJ4XS4809MsOJx4RP+/VPcsjvFx9nZ2kDqv9mk+bWJkDQzDYHV1lUQiQSgWpbO1RbexRjOTpWXkaOppyitBqqEwlVBoqFokSsbj5aTzEunP+Tm9Xo9YLEY6nRbAeDxOOBLhudnGLJUphcPUV+M8jsdJa0voqoquakNl/H5Crhne7u4hWTlZ2VmwbDYrYBY0EomITEqVCpFYjFgiQVLX8WkaCx4Pi14vC1fyqCpOl4uNVgvp8vKSwWAgQNbIN4HtdptSsUgwGBQTrCwv41lcRPX58Hm9Q2mqintmhtbmJtLGxgaFQkHklkqlSCaTokajUbHlSqUieuujdFrH7/fj8/lQVRVV9Qlpmobb7aZlOby4uKDf76PruthwLpcbfmABLWdzc7PMzz3EPTOPQ3Gh2J0o9umhZt2zyLLM+vo60vb2trg9y5kFvdb1HS4taYyN2niy9YhPvwocnhoMflT4+L3I0bccg58FAsF5bt8apdFoIJ2dnXFwcEAmkxEOLXf5fH44ciAQwHb3HmvPljk6zfPuS4beSYH3JzkOjrN8+GoQWJlndOQOa2sNpG63S7PZFABrTCsrcTZXDq3MZPkBsmxnYnySiXEZxe5iUnaI3npTFAc2m004/AumSqcc6d0LswAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/f86b77cf6c319ce0406b4fe9c8425c4b/e4706/homee_node-red-mqtt_10.avif 230w", "/en/static/f86b77cf6c319ce0406b4fe9c8425c4b/d1af7/homee_node-red-mqtt_10.avif 460w", "/en/static/f86b77cf6c319ce0406b4fe9c8425c4b/c400a/homee_node-red-mqtt_10.avif 897w"],
              "sizes": "(max-width: 897px) 100vw, 897px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/f86b77cf6c319ce0406b4fe9c8425c4b/a0b58/homee_node-red-mqtt_10.webp 230w", "/en/static/f86b77cf6c319ce0406b4fe9c8425c4b/bc10c/homee_node-red-mqtt_10.webp 460w", "/en/static/f86b77cf6c319ce0406b4fe9c8425c4b/10735/homee_node-red-mqtt_10.webp 897w"],
              "sizes": "(max-width: 897px) 100vw, 897px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/f86b77cf6c319ce0406b4fe9c8425c4b/81c8e/homee_node-red-mqtt_10.png 230w", "/en/static/f86b77cf6c319ce0406b4fe9c8425c4b/08a84/homee_node-red-mqtt_10.png 460w", "/en/static/f86b77cf6c319ce0406b4fe9c8425c4b/3a737/homee_node-red-mqtt_10.png 897w"],
              "sizes": "(max-width: 897px) 100vw, 897px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/f86b77cf6c319ce0406b4fe9c8425c4b/3a737/homee_node-red-mqtt_10.png",
              "alt": "homee Node-RED MQTT Verbindung zu Ihrer INSTAR IP-Kamera",
              "title": "homee Node-RED MQTT Verbindung zu Ihrer INSTAR IP-Kamera",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Now we need to add an `}<strong parentName="p">{`Then`}</strong>{` action we want to be triggered when the condition is met:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "899px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/55fb1888f0065d1e504b7661c2aef6ce/681f1/homee_node-red-mqtt_11.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "44.78260869565218%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAJCAIAAAC9o5sfAAAACXBIWXMAAA7DAAAOwwHHb6hkAAAB/UlEQVQoz13GT2/SYACA8X4ODya7KOtsRxqkhUFLKaXt+0L/0Xbggpi4sZEBYxMZG5kMSIhDYSvRLcuMiRiX6WEnEs/edBw8KtyMwexjGEa8+OR3eBCO43Rdj+k6wzAOh4MgCHwOxTy+J19+bH+/znz9nb0ar38bZ6/GucGfqY3BOD24fjUYIV6vN8BMcrvdOI4TBDE/Py8BGDEs2YrL8QeSbkDD4mWVFgAjQkaELIi6aHav3kB8tN9H+9lAgP0XSZJrq2mR8blQx4ITo/A5CkM9hPP+PdQ1e8eF3iUx1HH7VqmQR/rvLl63OouJ+KObHqZSYZ7fLJVX2qexZy292tb323r1pdHoGo2jWK0dq3XM+iGoHDzvfUJqu3v5zHokGlUURZZlVVWhJBZ2KrneZer4vXX8wbxhnV6YJ+fTXzw5j3R7B5efkbM3Z/u1mqqohmFIkgQB4MP81kYhLVsGFUh4ghMUmwxrS7SYoNiEJ7i0EFJx8sVuFenadrlcFkRRlmUAQATCYJivb23/qrwdZuzh8uFwZWL0uDNc7kx/uHo0SrZ+2h+RZrNZLBY1TTNNE0IIAQgJwk42uxaJAj+tBjmFDcosm+Qlk+NlllXYoMaFBJKyn5aQfr9v27YkSZqmAQAgAGFB2MzmVJrxzqIMjtMY9p+A0+mamank838Bst3MYUMLqFUAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/55fb1888f0065d1e504b7661c2aef6ce/e4706/homee_node-red-mqtt_11.avif 230w", "/en/static/55fb1888f0065d1e504b7661c2aef6ce/d1af7/homee_node-red-mqtt_11.avif 460w", "/en/static/55fb1888f0065d1e504b7661c2aef6ce/06569/homee_node-red-mqtt_11.avif 899w"],
              "sizes": "(max-width: 899px) 100vw, 899px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/55fb1888f0065d1e504b7661c2aef6ce/a0b58/homee_node-red-mqtt_11.webp 230w", "/en/static/55fb1888f0065d1e504b7661c2aef6ce/bc10c/homee_node-red-mqtt_11.webp 460w", "/en/static/55fb1888f0065d1e504b7661c2aef6ce/8bcb7/homee_node-red-mqtt_11.webp 899w"],
              "sizes": "(max-width: 899px) 100vw, 899px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/55fb1888f0065d1e504b7661c2aef6ce/81c8e/homee_node-red-mqtt_11.png 230w", "/en/static/55fb1888f0065d1e504b7661c2aef6ce/08a84/homee_node-red-mqtt_11.png 460w", "/en/static/55fb1888f0065d1e504b7661c2aef6ce/681f1/homee_node-red-mqtt_11.png 899w"],
              "sizes": "(max-width: 899px) 100vw, 899px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/55fb1888f0065d1e504b7661c2aef6ce/681f1/homee_node-red-mqtt_11.png",
              "alt": "homee Node-RED MQTT Verbindung zu Ihrer INSTAR IP-Kamera",
              "title": "homee Node-RED MQTT Verbindung zu Ihrer INSTAR IP-Kamera",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Here we will choose the `}<strong parentName="p">{`Webhook`}</strong>{` and type in the URL of the hook we just created in Node-RED. We set this to be `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`/homee/environment/`}</code>{`. So if your Node-RED installation is running on a computer with the IP `}<em parentName="p">{`192.168.2.48`}</em>{` and is using the default port `}<em parentName="p">{`1880`}</em>{` the complete URL is:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`http://192.168.2.48:1880/homee/environment/`}</code></pre></div>
    <p>{`Select the POST method and add a JSON body payload `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`{"val":"home"}`}</code>{`. This way we can reuse the webhook for all environment variables and add different actions in Node-RED depending on what value was posted - `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`home`}</code>{`, `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`away`}</code>{`, `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`sleeping`}</code>{`, `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`vacation`}</code>{`.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "893px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/00d2edef2d0d8b4425d79fe8ab95a3f6/6c745/homee_node-red-mqtt_12.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "78.26086956521739%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAQCAIAAACZeshMAAAACXBIWXMAAA7DAAAOwwHHb6hkAAAClUlEQVQoz22SS08TURiG57eAJEhrYcbO9XTundPpzHBomSulnTITpjallGhE4sJYNwTYmQiJl+jC4MKNGmpCIHHDn/C/GIPpjCal+ORdnM2TvOf7PqzZbAZB0Ol0BoNBFEVRe8PfaF4NXl6/uPz97Oz68Zfr3a//za/BZ8xxHNu2XdfVdV2WFahpnFDarW18fDD80H3+Ntx73XoylTfh3qvg4c933zHTNBFCsizNz8/ncjmCIPK5fEmRShXFcusAykWRIyUwGVrhcxR+fvUDI+4TJEmRJElRFE3TOI4jhJrr63dmZ3N3F/ILuXv5/FQWC4XZmZnR2Rn29NGu7ThBEPgpqqqura3Fcby0tMSybLFYLBQKizfBcXxubu78/Bz79v6T7bm+57uuGwQBQiiKoiRJcBznOI5hGPoWDMMQBHFycoJtJptoedm2bTOlUqmEYdjr9URRrKRACKvVqmEY2RtCmI5W3t/fxy4vLlZWVhzHMU3TMAxN09rtdrfbZRhGlmUxRVVVCKEkSUKKJEkcxx0eHmJxHJumWavVLMualEmS5DiO/QfDMACAUgrP8zRNj+XRaFSv1z3PQwhlchiG29vbgiBwE7AsK0mSqqqKokAIRVE8ODjA+v2+YRgIISsFQhiG4dbWlqZpAACWZSd9lmVpmuZ5/m/t09NT27aDIPA8z/d9hFA2sEqlUq1WAQDcTRRFKZfLAICxPBwOdV23LCubdla71+tl9W7LgiCIosgwzFg+OjpaXV31fd9xHN/3DcNotVr9fl+SpOzmpuTsIyRJjuXj4+PsvC3LQgiVy+V2u50kCcuymqbpul4qlaZ8AABFUWN5Z2en0WgkSRJFURzHjUYjiqJOp0OSJJ8yubAMjuMy+Q/2cDBTcfEPfwAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/00d2edef2d0d8b4425d79fe8ab95a3f6/e4706/homee_node-red-mqtt_12.avif 230w", "/en/static/00d2edef2d0d8b4425d79fe8ab95a3f6/d1af7/homee_node-red-mqtt_12.avif 460w", "/en/static/00d2edef2d0d8b4425d79fe8ab95a3f6/796e0/homee_node-red-mqtt_12.avif 893w"],
              "sizes": "(max-width: 893px) 100vw, 893px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/00d2edef2d0d8b4425d79fe8ab95a3f6/a0b58/homee_node-red-mqtt_12.webp 230w", "/en/static/00d2edef2d0d8b4425d79fe8ab95a3f6/bc10c/homee_node-red-mqtt_12.webp 460w", "/en/static/00d2edef2d0d8b4425d79fe8ab95a3f6/f0cd5/homee_node-red-mqtt_12.webp 893w"],
              "sizes": "(max-width: 893px) 100vw, 893px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/00d2edef2d0d8b4425d79fe8ab95a3f6/81c8e/homee_node-red-mqtt_12.png 230w", "/en/static/00d2edef2d0d8b4425d79fe8ab95a3f6/08a84/homee_node-red-mqtt_12.png 460w", "/en/static/00d2edef2d0d8b4425d79fe8ab95a3f6/6c745/homee_node-red-mqtt_12.png 893w"],
              "sizes": "(max-width: 893px) 100vw, 893px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/00d2edef2d0d8b4425d79fe8ab95a3f6/6c745/homee_node-red-mqtt_12.png",
              "alt": "homee Node-RED MQTT Verbindung zu Ihrer INSTAR IP-Kamera",
              "title": "homee Node-RED MQTT Verbindung zu Ihrer INSTAR IP-Kamera",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`After saving our Homeegram we can now give it a test run. The webhook we created in Node-RED had a debug node connected. So if you switch on the debug panel in Node-RED (small bug icon in the top right) we should be able to our POST request come in when we change our homee variable to `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`home`}</code>{`:`}</p>
    <p><img parentName="p" {...{
        "src": "/en/854a57b49d2eab398515f4dd3051100c/homee_node-red-mqtt_13.gif",
        "alt": "homee Node-RED MQTT Verbindung zu Ihrer INSTAR IP-Kamera"
      }}></img></p>
    <p>{`Now we can repeat those steps to create Homeegrams for every environment variable we want to use in Node-RED. To differentiate between those cases we will use a switch node in Node-RED and attach it to our webhook node (the complete `}<a parentName="p" {...{
        "href": "#flow-download"
      }}>{`flow can be downloaded below`}</a>{` and imported to Node-RED):`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "828px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/25497a193ec52b550c9d67ad66075cd3/8efc2/homee_node-red-mqtt_14.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "43.04347826086957%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAJCAIAAAC9o5sfAAAACXBIWXMAAA7DAAAOwwHHb6hkAAAA8UlEQVQoz43QQU7EMAwF0JyWLQdgyd1YcgFYgNTRqJ22aWzXruO4QdNqRMUIxFsli+9vOZxOp6ZpYoxEBAeISETDx+fl7R0R27YlIndn5q7rYoyqGubNMfCN+fXp+eXhcQJIKU3TlFIax7HfpJTC+XyOMbq7bcqNuyPiMAyAGMcRhgGnSeZZVRe5AoBgZrXWdeMH+7dsb55nQirLUte13iBiEJFaKzOLSLln5qWoajEvqvvQHTMHIjIzAOj7HgBE5McK7p5zrndEJOSczYyIEFG32cfinDMz/xq+rlTKfu2c87HwX+G9GQD25mNYVf8IfwGJQQlHqRa2xAAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/25497a193ec52b550c9d67ad66075cd3/e4706/homee_node-red-mqtt_14.avif 230w", "/en/static/25497a193ec52b550c9d67ad66075cd3/d1af7/homee_node-red-mqtt_14.avif 460w", "/en/static/25497a193ec52b550c9d67ad66075cd3/ec170/homee_node-red-mqtt_14.avif 828w"],
              "sizes": "(max-width: 828px) 100vw, 828px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/25497a193ec52b550c9d67ad66075cd3/a0b58/homee_node-red-mqtt_14.webp 230w", "/en/static/25497a193ec52b550c9d67ad66075cd3/bc10c/homee_node-red-mqtt_14.webp 460w", "/en/static/25497a193ec52b550c9d67ad66075cd3/712de/homee_node-red-mqtt_14.webp 828w"],
              "sizes": "(max-width: 828px) 100vw, 828px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/25497a193ec52b550c9d67ad66075cd3/81c8e/homee_node-red-mqtt_14.png 230w", "/en/static/25497a193ec52b550c9d67ad66075cd3/08a84/homee_node-red-mqtt_14.png 460w", "/en/static/25497a193ec52b550c9d67ad66075cd3/8efc2/homee_node-red-mqtt_14.png 828w"],
              "sizes": "(max-width: 828px) 100vw, 828px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/25497a193ec52b550c9d67ad66075cd3/8efc2/homee_node-red-mqtt_14.png",
              "alt": "homee Node-RED MQTT Verbindung zu Ihrer INSTAR IP-Kamera",
              "title": "homee Node-RED MQTT Verbindung zu Ihrer INSTAR IP-Kamera",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`The switch node will check the message that was send and look for the value `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`val`}</code>{`. If it is `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`home`}</code>{` the message will be forwarded through exit 1 and every message that carries an `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`away`}</code>{` will leave the node through exit 2.`}</p>
    <p>{`And the rest can be copy&pasted from our `}<a parentName="p" {...{
        "href": "/en/Advanced_User/INSTAR_MQTT_Broker/Node-RED/"
      }}>{`Node-RED MQTT Tutorial`}</a>{`. We need to set the message to the value that we need and connect it to a MQTT Out node for the command topic we want to update:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "828px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/25497a193ec52b550c9d67ad66075cd3/8efc2/homee_node-red-mqtt_14.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "43.04347826086957%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAJCAIAAAC9o5sfAAAACXBIWXMAAA7DAAAOwwHHb6hkAAAA8UlEQVQoz43QQU7EMAwF0JyWLQdgyd1YcgFYgNTRqJ22aWzXruO4QdNqRMUIxFsli+9vOZxOp6ZpYoxEBAeISETDx+fl7R0R27YlIndn5q7rYoyqGubNMfCN+fXp+eXhcQJIKU3TlFIax7HfpJTC+XyOMbq7bcqNuyPiMAyAGMcRhgGnSeZZVRe5AoBgZrXWdeMH+7dsb55nQirLUte13iBiEJFaKzOLSLln5qWoajEvqvvQHTMHIjIzAOj7HgBE5McK7p5zrndEJOSczYyIEFG32cfinDMz/xq+rlTKfu2c87HwX+G9GQD25mNYVf8IfwGJQQlHqRa2xAAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/25497a193ec52b550c9d67ad66075cd3/e4706/homee_node-red-mqtt_14.avif 230w", "/en/static/25497a193ec52b550c9d67ad66075cd3/d1af7/homee_node-red-mqtt_14.avif 460w", "/en/static/25497a193ec52b550c9d67ad66075cd3/ec170/homee_node-red-mqtt_14.avif 828w"],
              "sizes": "(max-width: 828px) 100vw, 828px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/25497a193ec52b550c9d67ad66075cd3/a0b58/homee_node-red-mqtt_14.webp 230w", "/en/static/25497a193ec52b550c9d67ad66075cd3/bc10c/homee_node-red-mqtt_14.webp 460w", "/en/static/25497a193ec52b550c9d67ad66075cd3/712de/homee_node-red-mqtt_14.webp 828w"],
              "sizes": "(max-width: 828px) 100vw, 828px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/25497a193ec52b550c9d67ad66075cd3/81c8e/homee_node-red-mqtt_14.png 230w", "/en/static/25497a193ec52b550c9d67ad66075cd3/08a84/homee_node-red-mqtt_14.png 460w", "/en/static/25497a193ec52b550c9d67ad66075cd3/8efc2/homee_node-red-mqtt_14.png 828w"],
              "sizes": "(max-width: 828px) 100vw, 828px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/25497a193ec52b550c9d67ad66075cd3/8efc2/homee_node-red-mqtt_14.png",
              "alt": "homee Node-RED MQTT Verbindung zu Ihrer INSTAR IP-Kamera",
              "title": "homee Node-RED MQTT Verbindung zu Ihrer INSTAR IP-Kamera",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`In this example we want to disable the motion detection area 1 when the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`home`}</code>{` environment variable is activated. This is done by sending `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`{"val":"0"}`}</code>{` to the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`alarm/area1/enable`}</code>{` topic.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/60cb979c0475dd4696f12981f02942b7/0b6f4/homee_node-red-mqtt_15.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "34.78260869565217%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAHCAIAAACHqfpvAAAACXBIWXMAAA7DAAAOwwHHb6hkAAABHklEQVQY01XPS0vDQBQF4Pz/teJGxZUgKlZdWGhs5pE0bWNnUiSF0JUItTFd1E4zmWReYkK0fpzNWRwu1wEAeh5o4KGHAIAAQQ8AhHGSJGmaLpfL1Wr1cWi9fk/Tt2ThhPBuBK6jsReF/Sh8mo6CEOIxwhPk55/ZvlEUBee87FRGpxeXi5NTB7vn2D0LgyHsH/nu8fNjr391M7jtefcPm2zN2F4IYa1VSumOlPJrtyuKwqFkRgkhs1kUDl4m7shHIURTP5gGwSbPq7o2xgghjDH6gLVWa+1QGjehcfz6k/m8DY3jPM8ZY+0pKaVSynS01nVdO/QPadIVQrbbbbvhnGdZxhhrq1JKSsk5Pxz/045to6oqxpgQ4vdzpVRZlt+vGG4xS0iu2wAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/60cb979c0475dd4696f12981f02942b7/e4706/homee_node-red-mqtt_15.avif 230w", "/en/static/60cb979c0475dd4696f12981f02942b7/d1af7/homee_node-red-mqtt_15.avif 460w", "/en/static/60cb979c0475dd4696f12981f02942b7/7f308/homee_node-red-mqtt_15.avif 920w", "/en/static/60cb979c0475dd4696f12981f02942b7/be2d7/homee_node-red-mqtt_15.avif 984w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/60cb979c0475dd4696f12981f02942b7/a0b58/homee_node-red-mqtt_15.webp 230w", "/en/static/60cb979c0475dd4696f12981f02942b7/bc10c/homee_node-red-mqtt_15.webp 460w", "/en/static/60cb979c0475dd4696f12981f02942b7/966d8/homee_node-red-mqtt_15.webp 920w", "/en/static/60cb979c0475dd4696f12981f02942b7/d4a71/homee_node-red-mqtt_15.webp 984w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/60cb979c0475dd4696f12981f02942b7/81c8e/homee_node-red-mqtt_15.png 230w", "/en/static/60cb979c0475dd4696f12981f02942b7/08a84/homee_node-red-mqtt_15.png 460w", "/en/static/60cb979c0475dd4696f12981f02942b7/c0255/homee_node-red-mqtt_15.png 920w", "/en/static/60cb979c0475dd4696f12981f02942b7/0b6f4/homee_node-red-mqtt_15.png 984w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/60cb979c0475dd4696f12981f02942b7/c0255/homee_node-red-mqtt_15.png",
              "alt": "homee Node-RED MQTT Verbindung zu Ihrer INSTAR IP-Kamera",
              "title": "homee Node-RED MQTT Verbindung zu Ihrer INSTAR IP-Kamera",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <h4 {...{
      "id": "flow-download",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#flow-download",
        "aria-label": "flow download permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Flow Download`}</h4>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "json"
    }}><pre parentName="div" {...{
        "className": "language-json"
      }}><code parentName="pre" {...{
          "className": "language-json"
        }}><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"id"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"181fefd6.961e6"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"type"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"http in"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"z"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"4ea30e4c.29d4"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"name"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"homee env"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"url"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"/homee/environment/"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"method"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"post"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"upload"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token boolean"
          }}>{`false`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"swaggerDoc"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`""`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"x"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`80`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"y"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`80`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"wires"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"405534e7.66348c"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"97d88190.082db"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"id"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"405534e7.66348c"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"type"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"http response"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"z"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"4ea30e4c.29d4"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"name"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"Webhook"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"statusCode"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`""`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"headers"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"content-type"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"application/json"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"x"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`238`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"y"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`40`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"wires"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"id"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"97d88190.082db"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"type"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"switch"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"z"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"4ea30e4c.29d4"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"name"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`""`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"property"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"payload.val"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"propertyType"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"msg"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"rules"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"t"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"eq"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"v"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"home"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"vt"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"str"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"t"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"eq"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"v"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"away"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"vt"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"str"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"checkall"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"true"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"repair"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token boolean"
          }}>{`false`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"outputs"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`2`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"x"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`229`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"y"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`80`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"wires"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"8a63a528.e6e3f8"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"80d7c418.063f58"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"id"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"8a63a528.e6e3f8"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"type"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"change"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"z"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"4ea30e4c.29d4"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"name"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"home"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"rules"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"t"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"set"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"p"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"payload"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"pt"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"msg"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"to"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"{\\"val\\":\\"0\\"}"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"tot"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"str"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"action"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`""`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"property"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`""`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"from"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`""`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"to"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`""`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"reg"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token boolean"
          }}>{`false`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"x"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`372`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"y"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`54`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"wires"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"e86399a7.4e76e8"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"id"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"80d7c418.063f58"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"type"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"change"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"z"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"4ea30e4c.29d4"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"name"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"away"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"rules"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"t"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"set"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"p"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"payload"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"pt"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"msg"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"to"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"{\\"val\\":\\"1\\"}"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"tot"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"json"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"action"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`""`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"property"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`""`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"from"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`""`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"to"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`""`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"reg"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token boolean"
          }}>{`false`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"x"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`372`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"y"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`100`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"wires"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"f96ffbe7.2eca78"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"id"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"e86399a7.4e76e8"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"type"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"mqtt out"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"z"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"4ea30e4c.29d4"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"name"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"alarm/area1/enable"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"topic"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"instar/000389888811/alarm/area1/enable"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"qos"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"1"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"retain"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"false"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"broker"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"9e4e460d.640f18"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"x"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`529`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"y"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`54`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"wires"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"id"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"f96ffbe7.2eca78"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"type"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"mqtt out"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"z"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"4ea30e4c.29d4"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"name"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"alarm/area1/enable"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"topic"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"instar/000389888811/alarm/area1/enable"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"qos"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"1"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"retain"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"false"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"broker"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"9e4e460d.640f18"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"x"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`527`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"y"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`101`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"wires"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"id"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"9e4e460d.640f18"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"type"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"mqtt-broker"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"z"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`""`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"name"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"IN-9010FHD"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"broker"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"192.168.2.165"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"port"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"8883"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"tls"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"b55f9f2e.fe529"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"clientid"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`""`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"usetls"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token boolean"
          }}>{`true`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"compatmode"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token boolean"
          }}>{`true`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"keepalive"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"60"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"cleansession"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token boolean"
          }}>{`true`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"birthTopic"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`""`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"birthQos"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"0"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"birthPayload"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`""`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"closeTopic"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`""`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"closeQos"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"0"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"closePayload"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`""`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"willTopic"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`""`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"willQos"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"0"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"willPayload"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`""`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"id"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"b55f9f2e.fe529"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"type"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"tls-config"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"z"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`""`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"name"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`""`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"cert"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`""`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"key"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`""`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"ca"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`""`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"certname"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"instar-cert.cert"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"keyname"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`""`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"caname"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`""`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"servername"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`""`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"verifyservercert"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token boolean"
          }}>{`false`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span></code></pre></div>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Note`}</strong>{` that this flow uses the MQTT topic `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`instar/000389888811/alarm/area1/enable`}</code>{` - you will have to swap `}<em parentName="li">{`000389888811`}</em>{` with the MAC address of your camera to make this work. See the `}<a parentName="li" {...{
          "href": "/en/Advanced_User/INSTAR_MQTT_Broker/"
        }}>{`MQTT Introduction`}</a>{` for more details.`}</li>
    </ul>
    <h3 {...{
      "id": "connecting-a-sensor-to-your-camera",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#connecting-a-sensor-to-your-camera",
        "aria-label": "connecting a sensor to your camera permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Connecting a Sensor to your Camera`}</h3>
    <p>{`Start by creating a new Homeegram and defining the `}<strong parentName="p">{`When`}</strong>{` condition:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "875px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/b9b60a8b1b24b33bb7baaea528ea2fb8/71c8e/homee_node-red-mqtt_16.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "24.347826086956523%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAFCAIAAADKYVtkAAAACXBIWXMAAA7DAAAOwwHHb6hkAAABIElEQVQY02OIiYmJiIjIzMzMz89PTExMiI+PTUo4ntn3v3z93+q1/+s2/C9Z9T97yf+cpWjod84yhuDg4KCgoICAAGdnZ3t7eycnZ0tLi46eZQcPv1qz497aXQ/XbL+/ZvPdNZvvrNlyD4LWbrm3Ysu9WzuvMfj6+4eFhnl4eOjr6xsbm1hYWBro6XlkFfg2dbvXtLnXtrvXtnk0dHm1TXSva4cgz/oOu/LGbecvMEzv6g8KCY6Ojo6Li4uPj/f29o6Li0uKidZVVjLT1THV1jLV0jQzMLC2sQWxwchcV0dbUWH96tUM03smBIUEh4eHBwcHh4aG+vv7JyUmxsbGqqiq6unr6+jq6ujqauvoaGtpQdg6urp6+vpKyspr164FANctfgKfgvMpAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/b9b60a8b1b24b33bb7baaea528ea2fb8/e4706/homee_node-red-mqtt_16.avif 230w", "/en/static/b9b60a8b1b24b33bb7baaea528ea2fb8/d1af7/homee_node-red-mqtt_16.avif 460w", "/en/static/b9b60a8b1b24b33bb7baaea528ea2fb8/c963a/homee_node-red-mqtt_16.avif 875w"],
              "sizes": "(max-width: 875px) 100vw, 875px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/b9b60a8b1b24b33bb7baaea528ea2fb8/a0b58/homee_node-red-mqtt_16.webp 230w", "/en/static/b9b60a8b1b24b33bb7baaea528ea2fb8/bc10c/homee_node-red-mqtt_16.webp 460w", "/en/static/b9b60a8b1b24b33bb7baaea528ea2fb8/a1ca4/homee_node-red-mqtt_16.webp 875w"],
              "sizes": "(max-width: 875px) 100vw, 875px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/b9b60a8b1b24b33bb7baaea528ea2fb8/81c8e/homee_node-red-mqtt_16.png 230w", "/en/static/b9b60a8b1b24b33bb7baaea528ea2fb8/08a84/homee_node-red-mqtt_16.png 460w", "/en/static/b9b60a8b1b24b33bb7baaea528ea2fb8/71c8e/homee_node-red-mqtt_16.png 875w"],
              "sizes": "(max-width: 875px) 100vw, 875px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/b9b60a8b1b24b33bb7baaea528ea2fb8/71c8e/homee_node-red-mqtt_16.png",
              "alt": "homee Node-RED MQTT Verbindung zu Ihrer INSTAR IP-Kamera",
              "title": "homee Node-RED MQTT Verbindung zu Ihrer INSTAR IP-Kamera",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`This time we have to choose a `}<strong parentName="p">{`Device`}</strong>{` as the trigger for our action:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "876px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/9feba57edf75ff35f02c03891441f41f/1b1d5/homee_node-red-mqtt_17.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "13.91304347826087%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAADCAIAAAAcOLh5AAAACXBIWXMAAA7DAAAOwwHHb6hkAAAAlUlEQVQI12OIiYmJjIzMyMgoKipKS0uLj4uLSU7oSy//X731f/vu/zVb/5du/F+2CQsq38wQHBwcFBQcHBzs6enp4uLi7uZu7+QY7RW4ILF6cWbjguSaObHlc+Mq0NC8+Mrp4UUMQUFBwSEh7u7uWlpa6hoaZmZmGhoaeoYGDp6uPiGBLn5eli721q6OaMjOw0XX3BgA4TxKw8n2bE4AAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/9feba57edf75ff35f02c03891441f41f/e4706/homee_node-red-mqtt_17.avif 230w", "/en/static/9feba57edf75ff35f02c03891441f41f/d1af7/homee_node-red-mqtt_17.avif 460w", "/en/static/9feba57edf75ff35f02c03891441f41f/5579a/homee_node-red-mqtt_17.avif 876w"],
              "sizes": "(max-width: 876px) 100vw, 876px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/9feba57edf75ff35f02c03891441f41f/a0b58/homee_node-red-mqtt_17.webp 230w", "/en/static/9feba57edf75ff35f02c03891441f41f/bc10c/homee_node-red-mqtt_17.webp 460w", "/en/static/9feba57edf75ff35f02c03891441f41f/21dbd/homee_node-red-mqtt_17.webp 876w"],
              "sizes": "(max-width: 876px) 100vw, 876px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/9feba57edf75ff35f02c03891441f41f/81c8e/homee_node-red-mqtt_17.png 230w", "/en/static/9feba57edf75ff35f02c03891441f41f/08a84/homee_node-red-mqtt_17.png 460w", "/en/static/9feba57edf75ff35f02c03891441f41f/1b1d5/homee_node-red-mqtt_17.png 876w"],
              "sizes": "(max-width: 876px) 100vw, 876px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/9feba57edf75ff35f02c03891441f41f/1b1d5/homee_node-red-mqtt_17.png",
              "alt": "homee Node-RED MQTT Verbindung zu Ihrer INSTAR IP-Kamera",
              "title": "homee Node-RED MQTT Verbindung zu Ihrer INSTAR IP-Kamera",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`We choose a ZigBee motion detector that has been connected to our homee gateway:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "870px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/adeadbc0b22b8401a31242b2ef0f683a/3f3b9/homee_node-red-mqtt_18.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "39.130434782608695%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAICAIAAAB2/0i6AAAACXBIWXMAAA7DAAAOwwHHb6hkAAABgElEQVQY02XLy46bMACFYR6omkZDGAgXg3F8wcaAaWwg0nTUtEXVYKmLrLqMlF0UZZf3yJ5HyEONpouq0vz6FmdznHmeT6cTIURKyTkXQmCMrbV10yweH13P+7xYfPTp4SEIQ2e323VdV1WVUkr8DWP8Ok1aqWi5BH4QusuV6/4TusvYe4pcL08S536/X69XKaXWWgjBiwJhvJ+mSbVVGD1TpjM4rPEWkwGtuxwZCKtVyLynPoqdcRyHYSjLsq5rKWUl5ZrSP6/T1HUQQiVlgXHJGKeEE8Ip5ZSCVej7/pcEOPM8n89npVTf98aYvu9LKX9P06+2NWn2Q1YvrHim9BsXXyl7YcV3Wf2sm02a6gQ4h8NhHEdCiBCiKArO+Rqhydpuuw1BAiBMsgxACLL3kWRZmudpngdRlObIuVwu+/1eKWWM2Ww2xhjGmLVWaw2zTGudAhD4/ioI/hcEAcpz53a7HY/Hsizbtm2apm1bQoi1tuu6OI4RQlEUhR96PyP0BqEMkT0z3s1zAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/adeadbc0b22b8401a31242b2ef0f683a/e4706/homee_node-red-mqtt_18.avif 230w", "/en/static/adeadbc0b22b8401a31242b2ef0f683a/d1af7/homee_node-red-mqtt_18.avif 460w", "/en/static/adeadbc0b22b8401a31242b2ef0f683a/36684/homee_node-red-mqtt_18.avif 870w"],
              "sizes": "(max-width: 870px) 100vw, 870px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/adeadbc0b22b8401a31242b2ef0f683a/a0b58/homee_node-red-mqtt_18.webp 230w", "/en/static/adeadbc0b22b8401a31242b2ef0f683a/bc10c/homee_node-red-mqtt_18.webp 460w", "/en/static/adeadbc0b22b8401a31242b2ef0f683a/bf818/homee_node-red-mqtt_18.webp 870w"],
              "sizes": "(max-width: 870px) 100vw, 870px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/adeadbc0b22b8401a31242b2ef0f683a/81c8e/homee_node-red-mqtt_18.png 230w", "/en/static/adeadbc0b22b8401a31242b2ef0f683a/08a84/homee_node-red-mqtt_18.png 460w", "/en/static/adeadbc0b22b8401a31242b2ef0f683a/3f3b9/homee_node-red-mqtt_18.png 870w"],
              "sizes": "(max-width: 870px) 100vw, 870px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/adeadbc0b22b8401a31242b2ef0f683a/3f3b9/homee_node-red-mqtt_18.png",
              "alt": "homee Node-RED MQTT Verbindung zu Ihrer INSTAR IP-Kamera",
              "title": "homee Node-RED MQTT Verbindung zu Ihrer INSTAR IP-Kamera",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`The action of this automation should trigger when the ZigBee sensor reports a motion:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "875px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/dbbfd0a3d58c6e1d9f0e7e489fd45e3d/71c8e/homee_node-red-mqtt_19.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "79.56521739130434%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAQCAIAAACZeshMAAAACXBIWXMAAA7DAAAOwwHHb6hkAAAC40lEQVQoz32S3U/bVhjG/WfsQ6Nap8DFFFNCEvyZgFM4Psd2fHzsYwrKpyWIWgKxHb4CaoocSKRKCG2j+6y2yxVNg3W9mqa2itqL3fH3TJpG1aBWAU376Xfx3jx63ouHqVar8/PzhUJhdXW1Xq+XiqWiV9ldavy9fXax/8e/O88u1n+72Hh63c3f/wl/ZQzDIMQmto0QkiQpm8kIGTkvZH6g934qN793V47x0iNredivSe0IlfutI2Ymp9iOQylVFCWRSMiSnEildFFcMlDV1BfBbZwRraw0rK1k1clbj6MHzJ8/n3XbkU2dcqlUqVSKxSKEsNlsuoRgTZ9TFJnnM4Iw7LQkTY6PH0Qdpru7d3epljdNjLFpmpZlGYbh+75FCFBVSZY5nucFYVhRkuIsG3U6zDfffdtcb9q27TgOhFDTNABAGIaUUlVVRVEcZK8gSRLLsp1Ohzk5Odne3gYA5PN5NEBV1SAIMMYIIdM0ZVnmuLf17xFFMR6PR1HEtFotz/Msy7JtW9M0hBAAIAgC13U1TVMU5f+a+/1+t9uFEFqWBQcAAHzfn52d5TgunU5PTEwkrpJMJkdHR9vtNlOr1RYWFi6bLcsihOi63mg0pqamYrHY5/8Fy7IjIyM7OzvM+fn54eGhruuO4xBCLp/3fX9OVXlRTKbTE5OT10ymUrGxsfbeHuN5nuM4CCH9HXMANINwAWMCYU4Qpqe4GY4fNieI6Ti7337AvHj+vNfrYYxd16WUupTOQrh3b2XrzmKN2CuUFgG4ZkmFVJSPg5AJtzZK5bJhGHiAhbGqaa16vQIhzmR1Ts6xqRybUtjk5ZFjUxrH51i21/CZs69+jFr3FwuFarVaGiyUOs6a7yfT6Ruf3lxczrQeauv76uaBvnGA1vfhVg8J2cQHH32yFvjM44dfhqsNI58nhFwuVNf1wPcFno99Nhp9YT79y3vyonj6qvZL3zt5WTp9XUV5/uMPR8LAfwMryUIrZ+dqPAAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/dbbfd0a3d58c6e1d9f0e7e489fd45e3d/e4706/homee_node-red-mqtt_19.avif 230w", "/en/static/dbbfd0a3d58c6e1d9f0e7e489fd45e3d/d1af7/homee_node-red-mqtt_19.avif 460w", "/en/static/dbbfd0a3d58c6e1d9f0e7e489fd45e3d/c963a/homee_node-red-mqtt_19.avif 875w"],
              "sizes": "(max-width: 875px) 100vw, 875px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/dbbfd0a3d58c6e1d9f0e7e489fd45e3d/a0b58/homee_node-red-mqtt_19.webp 230w", "/en/static/dbbfd0a3d58c6e1d9f0e7e489fd45e3d/bc10c/homee_node-red-mqtt_19.webp 460w", "/en/static/dbbfd0a3d58c6e1d9f0e7e489fd45e3d/a1ca4/homee_node-red-mqtt_19.webp 875w"],
              "sizes": "(max-width: 875px) 100vw, 875px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/dbbfd0a3d58c6e1d9f0e7e489fd45e3d/81c8e/homee_node-red-mqtt_19.png 230w", "/en/static/dbbfd0a3d58c6e1d9f0e7e489fd45e3d/08a84/homee_node-red-mqtt_19.png 460w", "/en/static/dbbfd0a3d58c6e1d9f0e7e489fd45e3d/71c8e/homee_node-red-mqtt_19.png 875w"],
              "sizes": "(max-width: 875px) 100vw, 875px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/dbbfd0a3d58c6e1d9f0e7e489fd45e3d/71c8e/homee_node-red-mqtt_19.png",
              "alt": "homee Node-RED MQTT Verbindung zu Ihrer INSTAR IP-Kamera",
              "title": "homee Node-RED MQTT Verbindung zu Ihrer INSTAR IP-Kamera",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`As action we choose a webhook `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`http://192.168.2.48:1880/homee/motionsensor/`}</code>{` and POST a `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`{"val":"1"}`}</code>{` to it:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/9138d7e453b50d4c5ca2cbd32c39b271/23266/homee_node-red-mqtt_20.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "63.47826086956522%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAIAAAAmMtkJAAAACXBIWXMAAA7DAAAOwwHHb6hkAAACIUlEQVQoz3XQT2/TMBgG8HwZJCTQ0rlN3SR1arIof+zEcRY77bqWVkgFhLp17cqt6r3TLlQ9IS1DGgdAKhfEge+FxhFYctnG+Om9+PC8j/wqh4eHQojRaDSZTIbDYb/X6wx6396sfi+//1x8vZ5+up59fnB+nX5RYhaLVFBKNU0DABimUdmtuNAaBeKFv/98jx1YQdcit6eHw/26vX13oXQODoRIOee2bTuOgwqUhU9BpVKvqVpV1cBODezUdlUNFM/qLtQePXn8/sOF8uPjVnayfr8/GAyGw2Ecx91u9+18vmfblBBKKA0ICyMex5RQEpDAD6IwbFmt/CJXXr98xRhLkoQWwjBst9tHR0e1Ws2yrGah1WrZto0QMgsIIQBAnufKdrtljGVZRgqu6wohptOpcZeu66ZpYozLRfV6/fLyUjk+PvZ9PwxDSikhxPO8MtxsNu/lEULPCo7j6Lq+Xq+Vq6urJEk6nU4URYQQ3/eFELPZzHVdy7J0Xb9d3mg0IISWZUEIV6uVslgsCCGMsbLZ930p5WQywRgTQkzTvNePMXYcB0J4dnambDabsjnLsna7zTlP03Q+nwdB4DiO8Q+EEMZY07Sb8Pn5ued5URSVp/Y8T0p5cnJi/Ed5OQDATXi5XHLOpZRpmkopgyCQUo7HY8MwPM/DGBuGYd6FEKpWqzfhPM8ppUmSxHHMOS//PB6PVVWFENYf0mg0VFX9e7A/0ZT3y8E23o4AAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/9138d7e453b50d4c5ca2cbd32c39b271/e4706/homee_node-red-mqtt_20.avif 230w", "/en/static/9138d7e453b50d4c5ca2cbd32c39b271/d1af7/homee_node-red-mqtt_20.avif 460w", "/en/static/9138d7e453b50d4c5ca2cbd32c39b271/7f308/homee_node-red-mqtt_20.avif 920w", "/en/static/9138d7e453b50d4c5ca2cbd32c39b271/fbc96/homee_node-red-mqtt_20.avif 923w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/9138d7e453b50d4c5ca2cbd32c39b271/a0b58/homee_node-red-mqtt_20.webp 230w", "/en/static/9138d7e453b50d4c5ca2cbd32c39b271/bc10c/homee_node-red-mqtt_20.webp 460w", "/en/static/9138d7e453b50d4c5ca2cbd32c39b271/966d8/homee_node-red-mqtt_20.webp 920w", "/en/static/9138d7e453b50d4c5ca2cbd32c39b271/e5eeb/homee_node-red-mqtt_20.webp 923w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/9138d7e453b50d4c5ca2cbd32c39b271/81c8e/homee_node-red-mqtt_20.png 230w", "/en/static/9138d7e453b50d4c5ca2cbd32c39b271/08a84/homee_node-red-mqtt_20.png 460w", "/en/static/9138d7e453b50d4c5ca2cbd32c39b271/c0255/homee_node-red-mqtt_20.png 920w", "/en/static/9138d7e453b50d4c5ca2cbd32c39b271/23266/homee_node-red-mqtt_20.png 923w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/9138d7e453b50d4c5ca2cbd32c39b271/c0255/homee_node-red-mqtt_20.png",
              "alt": "homee Node-RED MQTT Verbindung zu Ihrer INSTAR IP-Kamera",
              "title": "homee Node-RED MQTT Verbindung zu Ihrer INSTAR IP-Kamera",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`The corresponding webhook in Node-RED is even simpler this time:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/9138d7e453b50d4c5ca2cbd32c39b271/23266/homee_node-red-mqtt_20.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "63.47826086956522%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAIAAAAmMtkJAAAACXBIWXMAAA7DAAAOwwHHb6hkAAACIUlEQVQoz3XQT2/TMBgG8HwZJCTQ0rlN3SR1arIof+zEcRY77bqWVkgFhLp17cqt6r3TLlQ9IS1DGgdAKhfEge+FxhFYctnG+Om9+PC8j/wqh4eHQojRaDSZTIbDYb/X6wx6396sfi+//1x8vZ5+up59fnB+nX5RYhaLVFBKNU0DABimUdmtuNAaBeKFv/98jx1YQdcit6eHw/26vX13oXQODoRIOee2bTuOgwqUhU9BpVKvqVpV1cBODezUdlUNFM/qLtQePXn8/sOF8uPjVnayfr8/GAyGw2Ecx91u9+18vmfblBBKKA0ICyMex5RQEpDAD6IwbFmt/CJXXr98xRhLkoQWwjBst9tHR0e1Ws2yrGah1WrZto0QMgsIIQBAnufKdrtljGVZRgqu6wohptOpcZeu66ZpYozLRfV6/fLyUjk+PvZ9PwxDSikhxPO8MtxsNu/lEULPCo7j6Lq+Xq+Vq6urJEk6nU4URYQQ3/eFELPZzHVdy7J0Xb9d3mg0IISWZUEIV6uVslgsCCGMsbLZ930p5WQywRgTQkzTvNePMXYcB0J4dnambDabsjnLsna7zTlP03Q+nwdB4DiO8Q+EEMZY07Sb8Pn5ued5URSVp/Y8T0p5cnJi/Ed5OQDATXi5XHLOpZRpmkopgyCQUo7HY8MwPM/DGBuGYd6FEKpWqzfhPM8ppUmSxHHMOS//PB6PVVWFENYf0mg0VFX9e7A/0ZT3y8E23o4AAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/9138d7e453b50d4c5ca2cbd32c39b271/e4706/homee_node-red-mqtt_20.avif 230w", "/en/static/9138d7e453b50d4c5ca2cbd32c39b271/d1af7/homee_node-red-mqtt_20.avif 460w", "/en/static/9138d7e453b50d4c5ca2cbd32c39b271/7f308/homee_node-red-mqtt_20.avif 920w", "/en/static/9138d7e453b50d4c5ca2cbd32c39b271/fbc96/homee_node-red-mqtt_20.avif 923w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/9138d7e453b50d4c5ca2cbd32c39b271/a0b58/homee_node-red-mqtt_20.webp 230w", "/en/static/9138d7e453b50d4c5ca2cbd32c39b271/bc10c/homee_node-red-mqtt_20.webp 460w", "/en/static/9138d7e453b50d4c5ca2cbd32c39b271/966d8/homee_node-red-mqtt_20.webp 920w", "/en/static/9138d7e453b50d4c5ca2cbd32c39b271/e5eeb/homee_node-red-mqtt_20.webp 923w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/9138d7e453b50d4c5ca2cbd32c39b271/81c8e/homee_node-red-mqtt_20.png 230w", "/en/static/9138d7e453b50d4c5ca2cbd32c39b271/08a84/homee_node-red-mqtt_20.png 460w", "/en/static/9138d7e453b50d4c5ca2cbd32c39b271/c0255/homee_node-red-mqtt_20.png 920w", "/en/static/9138d7e453b50d4c5ca2cbd32c39b271/23266/homee_node-red-mqtt_20.png 923w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/9138d7e453b50d4c5ca2cbd32c39b271/c0255/homee_node-red-mqtt_20.png",
              "alt": "homee Node-RED MQTT Verbindung zu Ihrer INSTAR IP-Kamera",
              "title": "homee Node-RED MQTT Verbindung zu Ihrer INSTAR IP-Kamera",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`homee sends us a POST request with the payload `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`{"val":"1"}`}</code>{` through the webhook. All we need to do is to connect a MQTT Out node that updates the topic `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`/alarm/pushalarm`}</code>{` on our camera. Now every time the ZigBee sensor reports a motion an alarm will be triggered on our camera.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "860px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/f165cfc7fcc112ab3a820efd9b8ceac1/914ae/homee_node-red-mqtt_21.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "15.217391304347828%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAADCAIAAAAcOLh5AAAACXBIWXMAAA7DAAAOwwHHb6hkAAAAiklEQVQI12XLQQvCIBiAYf//fff6Ad07xxaDWCEtRmSDgUrC9HO2faQwregUe44vvAQApBSIaIxBxPQVY0wATMpS63qewy+mf9ZawpUpaLunbceV7julDpznD3Vi9YXu8nNRsuMNFCx3IQSprlW2ydbbVXNvcHTeW0TtvTPDE3o3Dji5KbxCWvjMb7PApsy0LJkvAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/f165cfc7fcc112ab3a820efd9b8ceac1/e4706/homee_node-red-mqtt_21.avif 230w", "/en/static/f165cfc7fcc112ab3a820efd9b8ceac1/d1af7/homee_node-red-mqtt_21.avif 460w", "/en/static/f165cfc7fcc112ab3a820efd9b8ceac1/384d4/homee_node-red-mqtt_21.avif 860w"],
              "sizes": "(max-width: 860px) 100vw, 860px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/f165cfc7fcc112ab3a820efd9b8ceac1/a0b58/homee_node-red-mqtt_21.webp 230w", "/en/static/f165cfc7fcc112ab3a820efd9b8ceac1/bc10c/homee_node-red-mqtt_21.webp 460w", "/en/static/f165cfc7fcc112ab3a820efd9b8ceac1/31b17/homee_node-red-mqtt_21.webp 860w"],
              "sizes": "(max-width: 860px) 100vw, 860px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/f165cfc7fcc112ab3a820efd9b8ceac1/81c8e/homee_node-red-mqtt_21.png 230w", "/en/static/f165cfc7fcc112ab3a820efd9b8ceac1/08a84/homee_node-red-mqtt_21.png 460w", "/en/static/f165cfc7fcc112ab3a820efd9b8ceac1/914ae/homee_node-red-mqtt_21.png 860w"],
              "sizes": "(max-width: 860px) 100vw, 860px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/f165cfc7fcc112ab3a820efd9b8ceac1/914ae/homee_node-red-mqtt_21.png",
              "alt": "homee Node-RED MQTT Verbindung zu Ihrer INSTAR IP-Kamera",
              "title": "homee Node-RED MQTT Verbindung zu Ihrer INSTAR IP-Kamera",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <h4 {...{
      "id": "flow-download-1",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#flow-download-1",
        "aria-label": "flow download 1 permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Flow Download`}</h4>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "json"
    }}><pre parentName="div" {...{
        "className": "language-json"
      }}><code parentName="pre" {...{
          "className": "language-json"
        }}><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"id"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"4080d8b0.359ba8"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"type"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"http in"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"z"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"4ea30e4c.29d4"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"name"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"homee motion"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"url"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"/homee/motionsensor/"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"method"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"post"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"upload"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token boolean"
          }}>{`false`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"swaggerDoc"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`""`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"x"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`90`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"y"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`220`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"wires"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"a073e475.1c5da8"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"66f1a6a1.b59f78"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"id"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"a073e475.1c5da8"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"type"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"http response"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"z"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"4ea30e4c.29d4"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"name"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"Webhook"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"statusCode"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`""`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"headers"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"content-type"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"application/json"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"x"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`238`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"y"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`180`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"wires"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"id"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"66f1a6a1.b59f78"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"type"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"mqtt out"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"z"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"4ea30e4c.29d4"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"name"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"alarm/pushalarm"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"topic"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"instar/000389888811/alarm/pushalarm"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"qos"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"1"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"retain"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"false"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"broker"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"9e4e460d.640f18"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"x"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`270`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"y"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`220`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"wires"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"id"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"9e4e460d.640f18"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"type"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"mqtt-broker"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"z"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`""`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"name"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"IN-9010FHD"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"broker"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"192.168.2.165"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"port"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"8883"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"tls"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"b55f9f2e.fe529"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"clientid"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`""`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"usetls"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token boolean"
          }}>{`true`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"compatmode"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token boolean"
          }}>{`true`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"keepalive"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"60"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"cleansession"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token boolean"
          }}>{`true`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"birthTopic"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`""`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"birthQos"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"0"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"birthPayload"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`""`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"closeTopic"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`""`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"closeQos"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"0"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"closePayload"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`""`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"willTopic"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`""`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"willQos"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"0"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"willPayload"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`""`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"id"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"b55f9f2e.fe529"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"type"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"tls-config"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"z"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`""`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"name"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`""`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"cert"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`""`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"key"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`""`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"ca"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`""`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"certname"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"instar-cert.cert"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"keyname"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`""`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"caname"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`""`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"servername"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`""`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"verifyservercert"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token boolean"
          }}>{`false`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span></code></pre></div>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Note`}</strong>{` that this flow uses the MQTT topic `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`instar/000389888811/alarm/pushalarm`}</code>{` - you will have to swap `}<em parentName="li">{`000389888811`}</em>{` with the MAC address of your camera to make this work. See the `}<a parentName="li" {...{
          "href": "/en/Advanced_User/INSTAR_MQTT_Broker/"
        }}>{`MQTT Introduction`}</a>{` for more details.`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      